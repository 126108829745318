
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "SongrequestSettings",
  computed: {
    ...mapGetters({
      settings: "SessionStore/settings",
      profile: "SessionStore/spotifyProfile",
    }),
    settingsAvailable() {
      return !!this.settings;
    },
    profileAvailable() {
      return !!this.profile;
    },
  },
  methods: {
    updateProperty(key: string, value: any): void {
      this.$store.dispatch("SessionStore/updateSettings", {
        key,
        value: !!value,
      });
    },
  },
});
