declare global {
  interface Window {
    configs: Record<ConfigKeys, string>;
  }
}
export enum ConfigKeys {
  API_URL_WS = "VUE_APP_API_URL_WS",
  API_URL = "VUE_APP_API_URL",
}
/**
 * @param {string} name Name of the Envirionment-Variable
 * @returns {string}
 */
export function getEnv(name: ConfigKeys): string {
  return window?.configs?.[name] ?? "";
}
