
import Vue from "vue";
export default Vue.extend({
  name: "BannedKeywordsCard",
  created() {
    this.fetchItems();
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("BannedKeyWords.Keyword"),
          value: "keyword",
          sortable: false,
        },
        {
          text: this.$t("BannedKeyWords.Actions"),
          sortable: false,
          value: "actions",
          align: "end",
        },
      ],
      items: [] as any[],
      editItem: {
        keyword: "",
      },
      defaultItem: {
        keyword: "",
      },
      editIndex: -1,
      dialog: false,
      loading: true,
      itemsPerPage: 10,
      page: 1,
      totalCount: 0,
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = false;
    },
    async deleteItemConfirm(data: any) {
      const item = this.items[this.editIndex] as any;
      const result = await this.axios.delete("/api/settings/keywords", {
        data: {
          keywordId: item.id,
        },
      });
      this.fetchItems();
      this.dialogDelete = false;
    },
    deleteItem(item: any) {
      this.editIndex = this.items.indexOf(item);
      this.editItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    updateOptions(data: any) {
      this.itemsPerPage = data.itemsPerPage;
      this.page = data.page;
      this.fetchItems();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editItem = Object.assign({}, this.defaultItem);
        this.editIndex = -1;
      });
    },
    fetchItems(): void {
      this.loading = true;
      this.axios
        .get("/api/settings/keywords", {
          params: {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
          },
        })
        .then((data) => {
          if (data.data.success) {
            this.totalCount = data.data.result.count;
            this.items = data.data.result.rows;
            this.loading = false;
          }
        });
    },
    async save(): Promise<void> {
      if (this.editItem.keyword) {
        await this.axios.put("/api/settings/keywords", {
          keyword: this.editItem.keyword,
        });
        this.close();
        this.fetchItems();
      }
    },
  },
});
