import { PluginObject } from "vue";
import { Modal } from "./modal";

const modal: PluginObject<Modal> = {
  install(vue, options) {
    vue.prototype.$modal = new Modal();
  },
};

export default modal;
