import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { io } from "socket.io-client";
import VueSocketIOExt from "vue-socket.io-extended";
import Vuetify from "vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";

import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import modal from "./plugins/modal";
import i18n from "./plugins/i18n";

const socket = io(
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_API_URL_WS
    : getEnv(ConfigKeys.API_URL_WS),
  {
    transports: ["websocket"],
    autoConnect: false,
    query: {
      token: "",
    },
  }
);
Vue.use(modal);
Vue.use(Vuetify);
Vue.use(VueSocketIOExt, socket);
Vue.use(VueAxios, axios);
Vue.use(VueCookies, { expire: "7d", domain: "*.splinti.de" });

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_API_URL
    : getEnv(ConfigKeys.API_URL);

Vue.config.productionTip = false;

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";
import { ConfigKeys, getEnv } from "./lib/utils";

const vuetify = new Vuetify({
  lang: {
    locales: { en, de },
    current: "en",
  },
  icons: {
    iconfont: "fa",
  },
  variations: {
    colors: ["primary", "secondary", "background", "surface"],
    lighten: 4,
    darken: 4,
  },
  theme: {
    themes: {
      dark: {
        background: "#03141c",
        primary: "#005f8f",
        surface: "#0b212b",
        secondary: "#8F3000",
        error: "#F46A6A",
        info: "#50A5F1",
        success: "#248a33",
        "on-success": "#FFFFFF",
        warning: "#F1B44C",
        "on-warning": "#FFFFFF",
      },
      light: {
        background: "#ededed",
        primary: "#00AAFF",
        surface: "#32394f",
        secondary: "#FF5500",
        error: "#F46A6A",
        info: "#50A5F1",
        success: "#34c349",
        "on-success": "#FFFFFF",
        warning: "#F1B44C",
        "on-warning": "#FFFFFF",
      },
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
