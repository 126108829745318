
import Vue, { CreateElement, PropType, VNode } from "vue";
import { v4 } from "uuid";
import { CustomComponentConfig } from "@/plugins/modal/modal";

export default Vue.extend({
  name: "CustomComponent",
  props: {
    config: {
      type: Object as PropType<CustomComponentConfig>,
      required: true,
    },
  },
  methods: {
    validate() {
      if ((this.$refs.customComponent as any).validate) {
        return (this.$refs.customComponent as any).validate();
      } else {
        return true;
      }
    },
  },
  render(h: CreateElement): VNode {
    return h(this.config.vueConstructor, {
      key: v4(),
      ref: "customComponent",
      on: {
        dataChanged: (key: any, value: any) => {
          this.$emit("dataChanged", key, value);
        },
      },
      ...this.config.data,
    });
  },
});
