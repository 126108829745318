
import { mapGetters } from "vuex";
import Vue from "vue";
import Modal from "./plugins/modal/Modal.vue";
import i18n from "./plugins/i18n";
import NewUserIntro from "./components/NewUserIntro.vue";

export default Vue.extend({
  components: { Modal, NewUserIntro },
  name: "App",

  data() {
    const lang = window.navigator.language.split("-")[0];
    return {
      language: Object.keys(i18n.messages).indexOf(lang),
      isDarkMode: true,
    };
  },
  created() {
    i18n.locale = window.navigator.language.split("-")[0];
    this.$vuetify.lang.current = window.navigator.language.split("-")[0];
    if (localStorage.getItem("isDarkMode") == null) {
      localStorage.setItem("isDarkMode", "true");
    }

    this.$vuetify.theme.dark = localStorage.getItem("isDarkMode") == "true";

    this.$store.dispatch("SessionStore/storeLogin");

    // if (localStorage.getItem("isDarkMode") == null) {
    //   this.$vuetify.theme.currentTheme
    // } else {
    // }
  },
  computed: {
    ...mapGetters({
      loggedIn: "SessionStore/loggedIn",
      isNewUser: "SessionStore/isNewUser",
    }),
    isStillLoading(): boolean {
      return this.loggedIn === undefined && this.isNewUser === undefined;
    },
    languages() {
      const value = Object.keys(i18n.messages);
      return value;
    },
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("isDarkMode", `${this.$vuetify.theme.dark}`);
    },
    setLanguage(lang: string) {
      i18n.locale = lang;
    },
    async forwardToTwitch() {
      const result = await this.axios.get("/api/auth/twitch/getUrl");
      window.location.href = result.data;
    },
  },
});
