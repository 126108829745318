import { Module } from "vuex";
import Vue from "vue";
import axios from "axios";

export interface SessionStoreState {
  loggedIn?: boolean;
  spotifyProfile?: any;
  settings?: any;
  isNewUser?: boolean;
}

const sessionStore: Module<SessionStoreState, unknown> = {
  namespaced: true,
  state: {
    loggedIn: undefined,
    spotifyProfile: undefined,
    settings: undefined,
    isNewUser: undefined,
  },
  getters: {
    loggedIn: (state) => state.loggedIn,
    spotifyProfile: (state) => state.spotifyProfile,
    settings: (state) => state.settings,
    isNewUser: (state) => state.isNewUser,
  },
  mutations: {
    setSpotifyProfile(state, payload) {
      state.spotifyProfile = payload;
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
    updateSettings(state, payload) {
      state.settings[payload.key] = payload.value;
    },
  },
  actions: {
    logOut(ctx) {
      ctx.state.loggedIn = false;
      Vue.$cookies.remove("jwt");
    },
    logIn(ctx) {
      ctx.state.loggedIn = true;
    },
    updateSettings(ctx, payload) {
      axios.post("/api/settings/update", {
        [payload.key]: payload.value,
      });
      ctx.commit("updateSettings", payload);
    },
    async getSettings(ctx) {
      axios
        .get("/api/settings/get")
        .then((result) => {
          ctx.commit("setSettings", result.data);
        })
        .catch((error) => {
          console.log({ loginSpotify: "loginSpotify", error });
        });
    },
    async loginSpotify(ctx) {
      axios
        .get("/api/auth/spotify/me")
        .then((result) => {
          ctx.commit("setSpotifyProfile", result.data);
        })
        .catch((error) => {
          console.log({ loginSpotify: "loginSpotify", error });
        });
    },
    async storeLogin(ctx) {
      const jwt = localStorage.getItem("jwt");
      if (!jwt) {
        ctx.state.loggedIn = false;
        return;
      }
      const tokenString = "Bearer " + jwt;

      // // set socket authorization
      // (this as any)._vm.$socket.client.io.opts.query["token"] = jwt;
      // (this as any)._vm.$socket.client.connect();

      // set axios authorization
      axios.defaults.headers.common["Authorization"] = tokenString;
      axios
        .get("/api/auth/twitch/me")
        .then(async () => {
          await this.dispatch("SessionStore/checkForNewUser");
          ctx.state.loggedIn = true;
        })
        .catch((err) => {
          ctx.state.loggedIn = false;
          localStorage.removeItem("jwt");
        });
    },
    async checkForNewUser(ctx) {
      axios
        .get<NewUserCheckResponse>("/api/auth/twitch/isNew")
        .then((response) => {
          ctx.state.isNewUser = response.data.isNew;
        });
    },
  },
};

type NewUserCheckResponse = {
  isNew: boolean;
};

export default sessionStore;
