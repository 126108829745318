
import Vue from "vue";
export default Vue.extend({
  name: "NewUserIntro",
  data() {
    return {
      isNewUser: true,
      e1: 1,
      spotifyConnected: false,
      spotifyConnectLoading: false,
      interval: 0,
      showTooltip: false,
      finishSetupLoading: false,
    };
  },
  methods: {
    selectAndCopy(event: any) {
      let element: any = document.getElementById("reward-name");
      console.log(event, element);
      if (element) {
        element.select();
        element.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.showTooltip = true;
        setTimeout(() => {
          this.showTooltip = false;
        }, 3000);
      }
    },
    async goToSpotifyLink(): Promise<void> {
      await this.pollStatus();
      this.e1 += 1;
    },
    async finishSetup(): Promise<void> {
      this.finishSetupLoading = true;
      try {
        const result = await this.axios.post<{ success: boolean }>(
          "/api/auth/twitch/finishSetup"
        );
        if (result.data.success) {
          setTimeout(async () => {
            await this.$store.dispatch("SessionStore/checkForNewUser");
            this.finishSetupLoading = false;
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async connectSpotify(): Promise<void> {
      this.spotifyConnectLoading = true;
      const result = await this.axios.get<{ url: string }>(
        "/api/auth/spotify/getUrl"
      );
      window.open(result.data.url, "_blank");
      // window.location.href = result.data.url;
      this.interval = setInterval(this.pollStatus.bind(this), 1000);
    },
    async pollStatus(): Promise<void> {
      let result = await this.axios.get<{ isAuthenticated: boolean }>(
        "/api/auth/spotify/pollStatus"
      );
      if (result.data.isAuthenticated) {
        this.spotifyConnected = true;
        clearInterval(this.interval);
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
});
