import Vue, { VNodeData, VueConstructor } from "vue";

export interface CustomComponentConfig {
  vueConstructor: VueConstructor;
  data: VNodeData;
}

export type ButtonConfig = {
  text: string;
  icon: string;
  color: string;
  iconPosition?: string;
  btnPosition?: string;
  validate?: boolean;
  onClick?: (data: any) => boolean | Promise<boolean>;
};

export type ModalParameter = {
  title: string;
  text?: string;
  minWidth?: string;
  onConfirm?: (data: any) => void;
  onAbort?: (data: any) => void;
  customComponentConfig?: CustomComponentConfig;
  buttons?: ButtonConfig[];
};

export class Modal {
  private eventBus = new Vue();

  public get EventBus() {
    return this.eventBus;
  }

  public show(params: ModalParameter) {
    this.EventBus.$emit("showModal", params);
  }
}
