import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "@/locales/de.json";
import en from "@/locales/en.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    de: de,
    en: en,
  },
  // disable the yellow "no translation" message in production
  // silentTranslationWarn: isProduction,
  // silentFallbackWarn: isProduction,
  // dateTimeFormats: dateTimeFormats,
  // numberFormats,
});

export default i18n;
