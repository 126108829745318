
import { v4 } from "uuid";
import Vue from "vue";
import CustomComponent from "./CustomComponent.vue";
import { ButtonConfig, ModalParameter } from "@/plugins/modal/modal";

export default Vue.extend({
  name: "CustomModal",
  components: {
    CustomComponent,
  },
  mounted() {
    this.$modal.EventBus.$on("showModal", (x: ModalParameter) => {
      this.showModal(x);
      this.modalOptions = x;
    });
  },
  methods: {
    leftIconVisible(btn: ButtonConfig) {
      return btn.icon && btn.iconPosition !== "right";
    },
    rightIconVisible(btn: ButtonConfig) {
      return btn.icon && btn.iconPosition === "right";
    },
    async clickBtn(btn: ButtonConfig) {
      this.btnsLoading = true;
      if (
        btn.validate &&
        this.$refs.customComponent &&
        (this.$refs.customComponent as any).validate
      ) {
        if ((this.$refs.customComponent as any).validate()) {
          if (btn.onClick) {
            const result = await btn.onClick(this.modalData);
            if (result) {
              this.modal = false;
            }
          } else {
            this.modal = false;
          }
        }
      } else {
        if (btn.onClick) {
          const result = await btn.onClick(this.modalData);
          if (result) {
            this.modal = false;
          }
        } else {
          this.modal = false;
        }
      }
      this.btnsLoading = false;
    },
    dataChanged(key: any, value: any) {
      this.modalData[key] = value;
    },
    showModal(x: ModalParameter) {
      this.title = x.title ?? "";
      this.text = x.text ?? "";
      this.modal = true;
      this.customComponentKey = v4();
    },
    async onConfirm() {
      if (this.showCustomComponent) {
        if (
          this.modalOptions.onConfirm &&
          (this.$refs.customComponent as any).validate
        ) {
          const validateResult = await (
            this.$refs.customComponent as any
          ).validate();
          if (validateResult) {
            this.modalOptions.onConfirm(this.modalData);
          } else {
            return false;
          }
        }
      } else {
        if (this.modalOptions.onConfirm) {
          this.modalOptions.onConfirm(this.modalData);
        }
      }
      return true;
    },
    onAbort() {
      this.modal = false;
      if (this.modalOptions.onAbort) {
        this.modalOptions.onAbort(this.modalData);
      }
    },
  },
  data() {
    return {
      btnsLoading: false,
      confirmButtonOverlay: false,
      alerts: [] as any[],
      title: "",
      text: "",
      modal: false,
      modalOptions: {} as ModalParameter,
      customComponentKey: v4(),
      modalData: {} as any,
    };
  },
  computed: {
    filteredButtons(): ButtonConfig[] {
      if (!this.modalOptions.buttons) {
        return [
          {
            text: "Ok",
            icon: "fa fa-check",
            color: "success",
            onClick: async () => {
              return await this.onConfirm();
            },
          },
          {
            text: "Abbrechen",
            icon: "fa fa-times",
            color: "error",
            onClick: () => {
              this.onAbort();
              return true;
            },
          },
        ];
      }
      return this.modalOptions.buttons;
    },
    leftBtns(): ButtonConfig[] {
      return this.filteredButtons.filter(
        (x) => x.btnPosition && x.btnPosition === "left"
      );
    },
    rightBtns(): ButtonConfig[] {
      return this.filteredButtons.filter(
        (x) => !x.btnPosition || x.btnPosition === "right"
      );
    },
    showCustomComponent(): boolean {
      return this.modalOptions.customComponentConfig !== undefined;
    },
  },
});
