
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "SpotifyConnectCard",
  data() {
    return {
      interval: 0,
    };
  },
  computed: {
    ...mapGetters({ spotifyProfile: "SessionStore/spotifyProfile" }),
    spotifyProfileNull(): any | null {
      return this.spotifyProfile == null;
    },
    image(): string | null {
      if (this.spotifyProfileNull) {
        return null;
      }
      if (!this.spotifyProfile.image) {
        return null;
      }
      return this.spotifyProfile.image;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async loginWithSpotify(): Promise<void> {
      const result = await this.axios.get("/api/auth/spotify/getUrl");
      window.open(result.data.url, "_blank");
      this.interval = setInterval(this.pollStatus.bind(this), 1000);
    },
    async pollStatus(): Promise<void> {
      let result = await this.axios.get<{ isAuthenticated: boolean }>(
        "/api/auth/spotify/pollStatus"
      );
      if (result.data.isAuthenticated) {
        this.$store.dispatch("SessionStore/loginSpotify");
        clearInterval(this.interval);
      }
    },
    openSpotifyProfile(): void {
      window.open(this.spotifyProfile.spotifyProfileUrl, "_blank");
    },
    unlinkSpotifyProfile(): void {
      this.$modal.show({
        title: this.$t("Accounts.Spotify.Connected.UnlinkProfile").toString(),
        text: this.$t(
          "Accounts.Spotify.Connected.UnlinkProfileLongText"
        ).toString(),
        onConfirm: () => {
          this.axios.delete("/api/auth/spotify/delete").finally(() => {
            window.location.reload();
          });
        },
      });
    },
  },
});
